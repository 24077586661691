import { useMemo } from "react";
import { Log, SearchLogic } from "blace-frontend-library";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { InputList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/PriceContent/components/PricingForm/PricingForm";
import { LISTING_TEXT_REG_EXP } from "@/src/const";
import { FormLogic } from "@/src/model";
import { PriceDurationBE } from "@/src/type/app";
import { SearchPrice, SearchPriceV2 } from "@/src/type/blaceV2/search/SearchType";

interface PriceSaveData {
  price: Partial<SearchPriceV2>;
}

interface UsePricingFormProps {
  pricingData?: Partial<SearchPriceV2 & SearchPrice>;
  priceSaveHandler?: (data: PriceSaveData) => Promise<Record<string, string>>;
}

export interface PricingFormType {
  [InputList.PricingDetails]: string;
  [InputList.ShowPricing]: boolean;
  [InputList.Price]: string;
  [InputList.PriceDuration]: string;
}

export function usePricingForm({ pricingData, priceSaveHandler }: UsePricingFormProps): {
  formik: FormikProps<PricingFormType>;
} {
  const defaultPricingDetails = pricingData?.details ?? "";

  const isV1Version = pricingData?.showPricing === undefined || pricingData.showPricing === null;

  const defaultShowPricing =
    (isV1Version ? pricingData?.displayPrice : pricingData?.showPricing) ?? true;

  const pricing = isV1Version
    ? pricingData?.minimumStartingPrice
    : (pricingData?.pricingValueInCents ? (pricingData?.pricingValueInCents / 100) : undefined);
  const defaultPrice = pricing?.toString() ?? "";

  const defaultPriceDuration =
    (isV1Version
      ? SearchLogic.convertPricingDurationToBackend(pricingData?.priceUnit)
      : pricingData?.pricingDuration
    ) ?? PriceDurationBE.PerDay;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [InputList.PricingDetails]: Yup.string()
          .matches(
            LISTING_TEXT_REG_EXP,
            "Please enter a details using letters, numbers or punctuation characters",
          )
          .max(1500, "Please enter details with a character count between 0 and 1500"),
        [InputList.ShowPricing]: Yup.boolean(),
        [InputList.Price]: Yup.number().typeError("Please enter a number"),
        [InputList.PriceDuration]: Yup.mixed().oneOf(Object.values(PriceDurationBE)),
      }),
    [],
  );

  const formik = useFormik<PricingFormType>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      [InputList.PricingDetails]: defaultPricingDetails,
      [InputList.ShowPricing]: defaultShowPricing,
      [InputList.Price]: defaultPrice,
      [InputList.PriceDuration]: defaultPriceDuration,
    },
    validationSchema,
    onSubmit: async (values, { setFieldError, setFieldTouched }) => {
      const formValuesData = {
        price: {
          details: Boolean(values[InputList.PricingDetails])
            ? values[InputList.PricingDetails]
            : undefined,
          showPricing: values[InputList.ShowPricing],
          pricingValueInCents: Boolean(values[InputList.Price])
            ? +values[InputList.Price] * 100
            : undefined,
          pricingDuration: Boolean(values[InputList.PriceDuration])
            ? values[InputList.PriceDuration]
            : undefined,
        },
      };
      // handle backend validation
      const unknownFields: string[] = [];
      const errors = priceSaveHandler ? await priceSaveHandler(formValuesData) : {};

      FormLogic.handleServerErrors(errors, values, setFieldError, unknownFields);

      // handle a case when backend returned non known fields
      if (Boolean(unknownFields.length)) {
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "usePricingForm.tsx",
          "onSubmitError",
          [unknownFields],
          "Unknown fields were returned from the server with errors.",
        );
        setFieldTouched(InputList.PricingDetails, true, false);
      }
    },
  });

  return {
    formik,
  };
}
