import React, { PropsWithChildren } from "react";
import { ListingCategories } from "@/src/component/view/ListingManagement/ListingManagement";
import HeadingSectionContent from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/HeadingSectionContent/HeadingSectionContent";
import styles from "./CategoryContentWrapper.module.scss";

interface CategoryContentWrapperProps extends PropsWithChildren {
  category: ListingCategories;
  onSaveFormData?: () => void;
}

function CategoryContentWrapper({
  category,
  onSaveFormData,
  children,
}: CategoryContentWrapperProps) {
  return (
    <div className={styles.categoryWrapper} data-testid={`${category}-content`}>
      <HeadingSectionContent onSaveFormData={onSaveFormData} title={category} />
      <div className={styles.contentSection}>{children}</div>
    </div>
  );
}

export default CategoryContentWrapper;
