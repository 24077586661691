import React, { useContext, useEffect, useRef, useState } from "react";
import { BaseButton, Log, SearchLogic, useBreakPointUp } from "blace-frontend-library";
import { BaseConfirmationModal, BaseIcon } from "@/src/component/base";
import { ListingCategories } from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { FormRef, PriceDurationBE } from "@/src/type/app";
import { VenueType } from "@/src/type/blaceV1";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";
import { uniqueId } from "@/src/util";
import RoomContentContext from "./RoomContentContext";
import styles from "./RoomsContent.module.scss";
import { EntryPopup } from "./component/EntryPopup";
import RoomFormManager from "./component/RoomFormManager/RoomFormManager";
import RoomListContainer from "./component/RoomListContainer/RoomListContainer";

const emptyRoom: Partial<SearchRoomV2> = {
  name: undefined,
  images: [],
  isNew: true,
  showPricing: true,
  pricingDuration: PriceDurationBE.PerDay,
};

function RoomsContent() {
  const [rooms, setRooms] = useState<Partial<SearchRoomV2>[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<Partial<SearchRoomV2> | undefined>(undefined);
  const isDesktop = useBreakPointUp("md");
  const [isEntryPopupVisible, setIsEntryPopupVisible] = useState(false);
  const [publishRoomId, setPublishRoomId] = useState<string>("");
  const [deleteRoomId, setDeleteRoomId] = useState<string>("");

  const { listingItemData, listingItemSaveHandler, isEditRequestSubmitting } =
    useContext(ListingManagementContext) || {};

  const formRef = useRef<FormRef | null>(null);

  const onSaveRoomFormData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleAddNewRoom = () => {
    const newRoom = { ...emptyRoom, id: uniqueId() };
    setRooms([...rooms, newRoom]);
  };

  const deleteRoom = async (roomId: string) => {
    try {
      const roomsToSave = listingItemData?.rooms?.filter((room) => room.id !== roomId);
      if (listingItemSaveHandler && roomsToSave) {
        await listingItemSaveHandler({ rooms: roomsToSave });
      }
      setDeleteRoomId("");
    } catch (error) {
      Log.logToDataDog(Log.LogLevel.ERROR, "RoomsContend.tsx", "deleteRoomError", [error]);
    }
  };

  const handleDeleteRoom = (roomId?: string) => {
    if (!roomId) {
      return;
    }
    setDeleteRoomId(roomId || "");
  };

  const updateRoomStatus = async (roomId: string, isPublished: boolean) => {
    try {
      const roomsToSave = listingItemData?.rooms?.map((room) => {
        if (room.id === roomId) {
          return { ...room, isPublished };
        }
        return room;
      });

      if (listingItemSaveHandler && roomsToSave) {
        await listingItemSaveHandler({ rooms: roomsToSave });
      }

      setPublishRoomId("");
    } catch (error) {
      Log.logToDataDog(Log.LogLevel.ERROR, "RoomsContent.tsx", "publishRoomError", [error]);
    }
  };

  const publishRoom = (roomId: string) => updateRoomStatus(roomId, true);
  const deactivateRoom = (roomId?: string) => updateRoomStatus(roomId || "", false);

  const handlePublishRoomClick = async (roomId?: string) => {
    if (!roomId) {
      return;
    }
    setPublishRoomId(roomId);
  };

  // support V1 data structure fallback
  useEffect(() => {
    if (Array.isArray(listingItemData?.rooms)) {
      return;
    }

    const v1VenueData = listingItemData?.data as VenueType.VenueItem;
    if (!v1VenueData?.rooms?.length) {
      return;
    }

    const roomsToPrefill: Partial<SearchRoomV2>[] = v1VenueData?.rooms.map((room) => {
      const newRoom: Partial<SearchRoomV2> = {
        id: uniqueId(),
        isNew: false,
        name: room.name,
        isPublished: true,
        images: [], // TODO should we migrate it somehow ?...
        sqFootage: room.space,
        numberOfFloors: room.floors,
        maxCapacity: room.capacity,
        description: room.details,
        showPricing: room.display_pricing,
        pricingDuration: SearchLogic.convertPricingDurationToBackend(room.short_pricing_details_label || undefined),
        pricingValueInCents: (room.price ? room.price * 100 : room.price) || undefined,
      };

      return {
        ...newRoom,
        isPublishEnabled: SearchLogic.isRoomValidForPublish(newRoom),
      };
    });

    if (roomsToPrefill?.length) {
      setRooms(roomsToPrefill);
    }

    if (selectedRoom) {
      const savedSelectedRoomData = roomsToPrefill?.find((room) => room.id === selectedRoom.id);
      setSelectedRoom(savedSelectedRoomData);
    }

    // Run only on mount, to migrate from V1 to V2 data structure
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const v1VenueData = listingItemData?.data as VenueType.VenueItem;

    if (!Array.isArray(listingItemData?.rooms) && !Array.isArray(v1VenueData?.rooms)) {
      setIsEntryPopupVisible(true);
    } else {
      setIsEntryPopupVisible(false);
    }
  }, [listingItemData]);

  //setting fetched rooms or if no rooms add default empty room
  //when listingDataItem is refetched set refetched room data to selected room
  useEffect(() => {
    const v1VenueData = listingItemData?.data as VenueType.VenueItem;
    const listingRooms = listingItemData?.rooms?.map((room) => ({
      ...room,
      isPublishEnabled: SearchLogic.isRoomValidForPublish(room),
    }));
    if (listingRooms?.length) {
      setRooms(listingRooms);
    } else if (!v1VenueData?.rooms?.length) {
      const newRoom = { ...emptyRoom, id: uniqueId() };
      setRooms([newRoom]);
    }

    if (selectedRoom) {
      const savedSelectedRoomData = listingRooms?.find((room) => room.id === selectedRoom.id);
      setSelectedRoom(savedSelectedRoomData);
    }

    // can't set selectedRoom to dependencies, it will cause infinite rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingItemData?.rooms]);

  //select first room from the list on initial load on desktop
  useEffect(() => {
    if (!selectedRoom && isDesktop) {
      setSelectedRoom(rooms[0]);
    }
  }, [rooms, selectedRoom, isDesktop]);

  return (
    <RoomContentContext.RoomContentContext.Provider
      value={{
        selectedRoom,
        setSelectedRoom,
        setRooms,
        allRooms: rooms,
        handlePublishRoomClick,
        handleDeleteRoom,
        deactivateRoom,
      }}
    >
      <CategoryContentWrapper
        category={ListingCategories.Rooms}
        onSaveFormData={onSaveRoomFormData}
      >
        <div>
          <div className={styles.addButtonWrapper}>
            <BaseButton
              startIcon={
                <BaseIcon
                  iconFileName="plusIcon"
                  iconAlt="add new room"
                  iconSize={20}
                  className={styles.addButtonIcon}
                />
              }
              className={styles.uploadButton}
              onClick={handleAddNewRoom}
              disabled={isEditRequestSubmitting}
            >
              Add New Room
            </BaseButton>
          </div>
          <div className={styles.roomsWrapper}>
            <RoomListContainer rooms={rooms} />
            <RoomFormManager ref={formRef} onSaveRoomFormData={onSaveRoomFormData} />
          </div>
        </div>
      </CategoryContentWrapper>
      <EntryPopup isOpen={isEntryPopupVisible} onClose={() => setIsEntryPopupVisible(false)} />
      {publishRoomId && (
        <BaseConfirmationModal
          handleClose={() => setPublishRoomId("")}
          handleConfirm={() => publishRoom(publishRoomId)}
          isOpen={!!publishRoomId}
          confirmationText="Are you sure you want to publish 
          this room?"
        />
      )}

      {deleteRoomId && (
        <BaseConfirmationModal
          handleClose={() => setDeleteRoomId("")}
          handleConfirm={() => deleteRoom(deleteRoomId)}
          isOpen={!!deleteRoomId}
          confirmationText="Are you sure you want to delete 
          this room?"
        />
      )}
    </RoomContentContext.RoomContentContext.Provider>
  );
}

export default RoomsContent;
