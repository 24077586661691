import React, { useContext } from "react";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { TagLabel } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/TagsContent/components/TagLabel";
import { ListingTag } from "@/src/type/app";
import { SearchAttribute } from "@/src/type/blaceV2/search/SearchType";
import styles from "./TagSection.module.scss";

interface TagSectionProps {
  sectionTitle: string;
  sectionDataType: string;
  tags: ListingTag[];
  selectedTags: SearchAttribute[];
  setSelectedTags: React.Dispatch<React.SetStateAction<SearchAttribute[]>>;
}

const TagSection = ({
  sectionTitle,
  sectionDataType,
  tags,
  selectedTags,
  setSelectedTags,
}: TagSectionProps) => {
  const { hasUnsavedData, setHasUnsavedData } = useContext(ListingManagementContext) || {};

  const selectedTagsCount = selectedTags.filter(
    (selectedTag) => selectedTag.attributeType === sectionDataType,
  ).length;

  const handleTagClick = (tag: ListingTag, isSelected: boolean) => {
    setSelectedTags((currentTags) =>
      isSelected
        ? currentTags.filter((selectedTag) => selectedTag.attributeId !== tag.value)
        : [...currentTags, { ...tag, attributeType: sectionDataType, attributeId: tag.value, value: "true" }],
    );

    if (!hasUnsavedData && setHasUnsavedData) {
      setHasUnsavedData(true);
    }
  };

  return (
    <div className={styles.tagSection}>
      <div className={styles.sectionHeader}>
        <h3 className={styles.sectionTitle}>{sectionTitle}</h3>
        <span className={styles.selectedTagsCount}>{selectedTagsCount} tags</span>
      </div>
      <div className={styles.tagList}>
        {tags.map((tag) => (
          <TagLabel
            key={tag.value}
            tag={tag}
            isSelected={
              !!selectedTags.find(
                (selectedTag) =>
                  selectedTag.attributeType === sectionDataType && tag.label === selectedTag.label,
              )
            }
            onClick={handleTagClick}
          />
        ))}
      </div>
    </div>
  );
};

export default TagSection;
