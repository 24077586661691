import React, { forwardRef, useContext, useEffect, useImperativeHandle } from "react";
import { FormControlLabel, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import { FormHelper, NumberHelper } from "blace-frontend-library";
import cn from "classnames";
import { useBlocker } from "react-router-dom";
import { TextareaDescription } from "@/src/component/base/TextareaDescription";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { SaveButton } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/DetailsContent/components/SaveButton";
import { UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { usePricingForm } from "@/src/hook/usePricingForm";
import { FormLogic } from "@/src/model";
import { BlockerArgs, FormRef, PriceDurationBE, PriceDurationFE } from "@/src/type/app";
import styles from "./PricingForm.module.scss";

export enum InputList {
  PricingDetails = "details",
  ShowPricing = "showPricing",
  Price = "pricingValueInCents",
  PriceDuration = "pricingDuration",
}

interface DetailsFormProps {
  onSavePricingFormData?: () => void;
}

function PricingForm(props: DetailsFormProps, ref: React.Ref<FormRef>) {
  const { onSavePricingFormData } = props;

  const {
    setIsSaveButtonDisabled,
    isEditRequestSubmitting,
    isSaveButtonDisabled,
    listingItemData,
    hasUnsavedData,
    setHasUnsavedData,
    listingItemSaveHandler,
  } = useContext(ListingManagementContext) || {};

  const { formik } = usePricingForm({
    priceSaveHandler: listingItemSaveHandler,
    pricingData: listingItemData?.price,
  });

  const isSaveDisabled = !formik.isValid || !formik.dirty || formik.isSubmitting;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: BlockerArgs) =>
      !!hasUnsavedData && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window && window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  // prevent data loss
  useEffect(() => {
    setHasUnsavedData && setHasUnsavedData(formik.dirty);

    if (formik.dirty && !formik.isSubmitting) {
      window.addEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }

    if (formik.isSubmitting) {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }
  }, [formik.dirty, formik.isSubmitting, setHasUnsavedData]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
      setHasUnsavedData && setHasUnsavedData(false);
    };
    // Run only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update the save button status
  useEffect(() => {
    setIsSaveButtonDisabled && setIsSaveButtonDisabled(isSaveDisabled);
  }, [isSaveDisabled, setIsSaveButtonDisabled]);

  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
  }));

  return (
    <div className={styles.pricingForm}>
      <form onSubmit={formik.handleSubmit}>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values[InputList.ShowPricing]}
              onChange={(e) => {
                formik.setFieldValue(InputList.ShowPricing, e.target.checked);
              }}
              className={styles.switchPricing}
            />
          }
          className={styles.showPricingControl}
          label={
            <Typography component="div">
              <span className={styles.showPricingControlName}>Show pricing</span>
              <span className={styles.showPricingControlText}>
                If pricing is turned Off, your venue will display "Inquire for Pricing" on the
                listing card and in the pricing section
              </span>
            </Typography>
          }
        />
        <div className={styles.formSection}>
          <div className={styles.formItemsWrapper}>
            <div className={cn(styles.formItem, styles.twoInRow)}>
              <label
                htmlFor={InputList.Price}
                className={cn(styles.inputLabel, {
                  [styles.required]: formik.values[InputList.ShowPricing],
                })}
              >
                Pricing value
              </label>
              <TextField
                disabled={isEditRequestSubmitting || !formik.values[InputList.ShowPricing]}
                value={`$${NumberHelper.formatFormInputValue(
                  formik.values[InputList.Price],
                  true,
                )}`}
                onChange={(e) => {
                  FormLogic.setFormikCleanedNumberValue(
                    e?.target?.value.slice(1),
                    formik,
                    InputList.Price,
                  );
                }}
                helperText={FormHelper.formikErrorMessage(formik, InputList.Price)}
                error={FormHelper.formikCheckError(formik, InputList.Price)}
                fullWidth
                className={styles.textField}
                id={InputList.Price}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <div className={cn(styles.formItem, styles.twoInRow)}>
              <label
                htmlFor={InputList.PriceDuration}
                className={cn(styles.inputLabel, {
                  [styles.required]: formik.values[InputList.ShowPricing],
                })}
              >
                Pricing duration
              </label>
              <Select
                labelId={InputList.PriceDuration}
                disabled={isEditRequestSubmitting || !formik.values[InputList.ShowPricing]}
                value={formik.values[InputList.PriceDuration]}
                fullWidth
                className={styles.textField}
                error={FormHelper.formikCheckError(formik, InputList.PriceDuration)}
                id={InputList.PriceDuration}
                onChange={(e) => {
                  formik.setFieldValue(InputList.PriceDuration, e.target.value);
                }}
              >
                <MenuItem value={PriceDurationBE.PerDay}>{PriceDurationFE.PerDay}</MenuItem>
                <MenuItem value={PriceDurationBE.PerHalfDay}>{PriceDurationFE.PerHalfDay}</MenuItem>
                <MenuItem value={PriceDurationBE.PerHour}>{PriceDurationFE.PerHour}</MenuItem>
                <MenuItem value={PriceDurationBE.PerPerson}>{PriceDurationFE.PerPerson}</MenuItem>
              </Select>
            </div>
          </div>
        </div>
        <div className={styles.formItem}>
          <label
            htmlFor={InputList.PricingDetails}
            className={cn(styles.inputLabel, styles.required)}
          >
            Pricing details
          </label>
          <TextField
            className={cn(styles.textField, styles.pricingDescription)}
            placeholder="Add any relevant pricing info, like weekday vs weekend pricing"
            fullWidth
            disabled={isEditRequestSubmitting}
            value={formik.values[InputList.PricingDetails]}
            onChange={(e) => {
              formik.setFieldValue(InputList.PricingDetails, e?.target?.value ?? "");
            }}
            helperText={FormHelper.formikErrorMessage(formik, InputList.PricingDetails)}
            error={FormHelper.formikCheckError(formik, InputList.PricingDetails)}
            multiline
            rows={4}
            id={InputList.PricingDetails}
          />
          <TextareaDescription
            value={formik.values[InputList.PricingDetails]}
            isError={FormHelper.formikCheckError(formik, InputList.PricingDetails)}
            maxChars={1500}
            minChars={0}
          />
        </div>
      </form>
      <div className={styles.formActionButtons}>
        <SaveButton
          isFullWidth
          isSaveButtonLoading={Boolean(isEditRequestSubmitting)}
          isSaveButtonDisabled={Boolean(isSaveButtonDisabled)}
          onSaveFormData={onSavePricingFormData}
        />
      </div>
    </div>
  );
}

export default forwardRef(PricingForm);
