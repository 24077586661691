import { useMemo } from "react";
import { Log } from "blace-frontend-library";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { InputList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/component/RoomForm/RoomForm";
import { LISTING_TEXT_REG_EXP } from "@/src/const";
import { FormLogic } from "@/src/model";
import { PriceDurationBE } from "@/src/type/app";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";

interface UseRoomFormProps {
  roomData?: Partial<SearchRoomV2>;
  roomSaveHandler: (roomData: Partial<SearchRoomV2>) => Promise<Record<string, string>>;
}

export interface RoomFormType {
  [InputList.id]: string;
  [InputList.RoomName]: string;
  [InputList.RoomDescription]: string;
  [InputList.FloorsNumber]: string;
  [InputList.SquareFootage]: string;
  [InputList.Capacity]: string;
  [InputList.Images]: string[];
  [InputList.ShowPricing]: boolean;
  [InputList.Price]: string;
  [InputList.PriceDuration]: string;
}

export function useRoomForm({ roomData, roomSaveHandler }: UseRoomFormProps): {
  formik: FormikProps<RoomFormType>;
} {
  const roomId = roomData?.id ?? "";
  const defaultName = roomData?.name ?? "";
  const defaultDescription = roomData?.description ?? "";
  const defaultCapacity = roomData?.maxCapacity?.toString() ?? "";
  const defaultFloorsNumber = roomData?.numberOfFloors?.toString() ?? "";
  const defaultSqFootage = roomData?.sqFootage?.toString() ?? "";
  const defaultImages = roomData?.images ?? [];
  const defaultShowPricing = roomData?.showPricing ?? true;
  const defaultPrice =
    (
      roomData?.pricingValueInCents && roomData.pricingValueInCents / 100
    )?.toString() ?? "";
  const defaultPriceDuration = roomData?.pricingDuration ??  PriceDurationBE.PerDay;

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [InputList.RoomName]: Yup.string()
          .matches(
            LISTING_TEXT_REG_EXP,
            "Please enter a name using letters, numbers or punctuation characters",
          )
          .min(2, "Please enter a name with a character count between 2 and 75")
          .max(75, "Please enter a name with a character count between 2 and 75"),

        [InputList.RoomDescription]: Yup.string()
          .matches(
            LISTING_TEXT_REG_EXP,
            "Please enter a description using letters, numbers or punctuation characters",
          )
          .min(50, "Please enter a description with a character count between 50 and 3,000")
          .max(3000, "Please enter a description with a character count between 50 and 3,000"),

        [InputList.FloorsNumber]: Yup.number()
          .typeError("Please enter a number")
          .min(1, "Please enter a floor number between 1 and 50")
          .max(50, "Please enter a floor number between 1 and 50"),

        [InputList.SquareFootage]: Yup.number()
          .typeError("Please enter a number")
          .min(1, "Please enter sq. footage between 1 and 500,000")
          .max(500000, "Please enter sq. footage between 1 and 500,000"),
        [InputList.Capacity]: Yup.number()
          .typeError("Please enter a number")
          .min(1, "Please enter a guest count between 1 and 300,000")
          .max(300000, "Please enter a guest count between 1 and 300,000"),
        [InputList.ShowPricing]: Yup.boolean(),
        [InputList.Images]: Yup.array().of(Yup.string()),
        [InputList.Price]: Yup.number().typeError("Please enter a number"),

        [InputList.PriceDuration]: Yup.mixed().oneOf(Object.values(PriceDurationBE)),
      }),
    [],
  );

  const formik = useFormik<RoomFormType>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      [InputList.id]: roomId,
      [InputList.RoomName]: defaultName,
      [InputList.RoomDescription]: defaultDescription,
      [InputList.FloorsNumber]: defaultFloorsNumber,
      [InputList.SquareFootage]: defaultSqFootage,
      [InputList.Capacity]: defaultCapacity,
      [InputList.Images]: defaultImages,
      [InputList.ShowPricing]: defaultShowPricing,
      [InputList.Price]: defaultPrice,
      [InputList.PriceDuration]: defaultPriceDuration,
    },
    validationSchema,
    onSubmit: async (values, { setFieldError, setFieldTouched }) => {
      const formValuesData = {
        id: values[InputList.id],
        name: Boolean(values[InputList.RoomName]) ? values[InputList.RoomName] : undefined,
        description: Boolean(values[InputList.RoomDescription])
          ? values[InputList.RoomDescription]
          : undefined,
        sqFootage: Boolean(values[InputList.SquareFootage])
          ? +values[InputList.SquareFootage]
          : undefined,
        numberOfFloors: Boolean(values[InputList.FloorsNumber])
          ? +values[InputList.FloorsNumber]
          : undefined,
        maxCapacity: Boolean(values[InputList.Capacity]) ? +values[InputList.Capacity] : undefined,
        images: values[InputList.Images],
        showPricing: values[InputList.ShowPricing],
        pricingValueInCents: Boolean(values[InputList.Price]) ? +values[InputList.Price] * 100 : undefined,
        pricingDuration: Boolean(values[InputList.PriceDuration])
          ? values[InputList.PriceDuration]
          : undefined,
      };
      // handle backend validation
      const unknownFields: string[] = []; 
      const errors = await roomSaveHandler(formValuesData);

      FormLogic.handleServerErrors(errors, values, setFieldError, unknownFields);

      // handle a case when backend returned non known fields
      if (Boolean(unknownFields.length)) {
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "useRoomForm.tsx",
          "onSubmitError",
          [unknownFields],
          "Unknown fields were returned from the server with errors.",
        );
        setFieldTouched(InputList.RoomName, true, false);
      }
    },
  });

  return {
    formik,
  };
}
