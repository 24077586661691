import React from "react";
import { Divider, Typography } from "@mui/material";
import {
  BaseButton,
  KEYS,
  SearchLogic,
  SharedConfigManager,
} from "blace-frontend-library";
import { useNavigate } from "react-router-dom";
import { BaseIcon } from "@/src/component/base";
import { IconBlock } from "@/src/component/view/Dashboard/component/IconBlock";
import { ListingTitleCell } from "@/src/component/view/Listings/component/ListingTitleCell";
import { ListingContactCell } from "@/src/component/view/Listings/component/ListingsTable/component/ListingContactCell";
import { ListingStatusLabel } from "@/src/component/view/Listings/component/ListingsTable/component/ListingStatusLabel";
import { SearchItem } from "@/src/type/blaceV2/search/SearchType";
import styles from "./ListingCard.module.scss";

interface ListingCardInfoProps {
  listing: SearchItem;
}

function ListingCardInfo({ listing }: ListingCardInfoProps) {
  const navigate = useNavigate();
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);
  const NO_DATA_TEXT = "---";
  const { dataType, searchId } = listing;

  const roomsNumber = SearchLogic.getRoomsNumber(listing);

  const goToListingEdit = () => {
    navigate(`/listings/${dataType}/${searchId}`);
  };

  return (
    <>
      <ListingTitleCell listing={listing} />
      <Divider className={styles.divider} />
      <div className={styles.infoBlock}>
        <Typography className={styles.cardHeading}>Status</Typography>
        <ListingStatusLabel listingStatus={listing.status} />
      </div>
      <div className={styles.infoBlock}>
        <Typography className={styles.cardHeading}>Number of rooms</Typography>
        <IconBlock icon={`${SVG_URL}/signInIcon.svg`}>
          {roomsNumber
            ? roomsNumber === 1
              ? "1 room"
              : `${roomsNumber} rooms`
            : NO_DATA_TEXT}
        </IconBlock>
      </div>
      <div className={styles.infoBlock}>
        <Typography className={styles.cardHeading}>Price</Typography>
        <Typography className={styles.listingPrice}>
          {SearchLogic.formatSearchPricing(listing)}
        </Typography>
      </div>
      <div className={styles.infoBlock}>
        <Typography className={styles.cardHeading}>
          Notification Contact
        </Typography>
        <ListingContactCell listing={listing} />
      </div>
      <Divider className={styles.divider} />
      <div className={styles.cardButtons}>
        <div className={styles.buttonWrapper}>
          <BaseButton
            className={styles.listingActionButton}
            startIcon={
              <BaseIcon
                iconFileName="eyeIconBlue"
                iconAlt="eye icon"
                iconSize={20}
              />
            }
          >
            Preview
          </BaseButton>
        </div>
        <div className={styles.buttonWrapper}>
          <BaseButton
            className={styles.listingActionButton}
            onClick={goToListingEdit}
            startIcon={
              <BaseIcon
                iconFileName="editIcon"
                iconAlt="pen icon"
                iconSize={20}
              />
            }
          >
            Edit
          </BaseButton>
        </div>
      </div>
    </>
  );
}

export default ListingCardInfo;
