import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { FILTERS } from "blace-frontend-library";
import { useBlocker } from "react-router-dom";
import { InfoAlert } from "@/src/component/base";
import { ListingCategories } from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { CategoryContentWrapper } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/CategoryContentWrapper";
import { UNSAVED_CHANGES_WARNING_TEXT } from "@/src/const";
import { FormLogic } from "@/src/model";
import { BlockerArgs, ListingTagSectionType } from "@/src/type/app";
import { SearchAttribute } from "@/src/type/blaceV2/search/SearchType";
import { TagsSectionList } from "./components/TagsSectionList";

function TagsContent() {
  const tagsSections: ListingTagSectionType[] = [
    FILTERS.venueType,
    FILTERS.bestUsedFor,
    FILTERS.amenities,
  ];

  const {
    listingItemData,
    listingItemSaveHandler,
    hasUnsavedData,
    setHasUnsavedData,
    isEditRequestSubmitting,
    setIsSaveButtonDisabled,
  } = useContext(ListingManagementContext) || {};

  const [selectedTags, setSelectedTags] = useState<SearchAttribute[]>([]);

  const isSaveDisabled = isEditRequestSubmitting || !hasUnsavedData;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }: BlockerArgs) =>
      !!hasUnsavedData && currentLocation.pathname !== nextLocation.pathname,
  );

  const onSaveTagsFormData = async () => {
    if (listingItemSaveHandler) {
      await listingItemSaveHandler({ attributes: selectedTags });
    }
  };

  useEffect(() => {
    if (listingItemData?.attributes?.length) {
      setSelectedTags(listingItemData.attributes);
    }
  }, [listingItemData?.attributes]);

  useEffect(() => {
    if (blocker.state === "blocked") {
      if (window && window.confirm(UNSAVED_CHANGES_WARNING_TEXT)) {
        blocker.proceed();
      } else {
        blocker.reset();
      }
    }
  }, [blocker]);

  // prevent data loss
  useEffect(() => {
    if (hasUnsavedData) {
      window.addEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    } else {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
    }
  }, [hasUnsavedData]);

  useEffect(() => {
    return () => {
      window.removeEventListener("beforeunload", FormLogic.beforeUnloadWindowHandler);
      setHasUnsavedData && setHasUnsavedData(false);
    };
    // Run only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update the save button and other statuses
  useEffect(() => {
    setIsSaveButtonDisabled && setIsSaveButtonDisabled(isSaveDisabled);
  }, [isSaveDisabled, setIsSaveButtonDisabled]);

  return (
    <CategoryContentWrapper category={ListingCategories.Tags} onSaveFormData={onSaveTagsFormData}>
      <Box>
        <InfoAlert text="To ensure clients can find your venue, please select at least single tag per section" />
        <TagsSectionList
          sections={tagsSections}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
      </Box>
    </CategoryContentWrapper>
  );
}

export default TagsContent;
