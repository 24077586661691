import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, IconButton, Typography } from "@mui/material";
import { BaseButton, DragBar, Log, useBreakPointUp, useDragToClose } from "blace-frontend-library";
import cn from "classnames";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { UploadPlaceholder } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/PhotosContent/component/UploadPlaceholder";
import RoomContentContext from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/RoomContentContext";
import { RoomPhoto } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/component/RoomPhoto";
import { PhotoLogic } from "@/src/model";
import { RoomPhotoFile } from "@/src/type/app";
import styles from "./RoomPhotosPopup.module.scss";

interface RoomPhotosPopupProps {
  photoFiles: RoomPhotoFile[];
  setPhotoFiles: Dispatch<SetStateAction<RoomPhotoFile[]>>;
  isOpen: boolean;
  selectedPhotos: string[];
  handleClose: () => void;
  handleSelectRoomPhotos: (photos: string[]) => void;
}

interface CustomPaperProps {
  children?: React.ReactNode;
}

const CustomPaper = memo(function CPaper(props: CustomPaperProps) {
  return <>{props.children}</>;
});

function RoomPhotosPopup({
  isOpen,
  photoFiles,
  selectedPhotos,
  setPhotoFiles,
  handleClose,
  handleSelectRoomPhotos,
}: RoomPhotosPopupProps) {
  const [error, setError] = useState<string>("");
  const [isPhotoUploading, setIsPhotoUploading] = useState(false);
  const [selectedHashes, setSelectedHashes] = useState<string[]>([]);
  const hiddenFileInputRef = useRef<HTMLInputElement | null>(null);
  const isDesktop = useBreakPointUp("md");

  const { selectedRoom } = RoomContentContext.useRoomContext();

  const { requestSearchId } = useContext(ListingManagementContext) || {};

  const { handleDrag, handleDragStop } = useDragToClose({
    onDragStop: handleClose,
  });

  const handleDeleteIconClick = (photoImageHash?: string) => {
    const filteredFiles = photoFiles.filter((photo) => photo.imageHash !== photoImageHash);
    setPhotoFiles(filteredFiles);
  };

  useEffect(() => {
    const images = selectedPhotos.length ? selectedPhotos : selectedRoom?.images;
    setSelectedHashes(images || []);
  }, [selectedRoom?.images, selectedPhotos]);

  const updateSelectedHashes = useCallback(
    (isSelected: boolean, imageHash?: string) => {
      if (!imageHash) {
        return;
      }
      if (isSelected) {
        setSelectedHashes(prevHashes => [...prevHashes, imageHash]);
      } else {
        setSelectedHashes(selectedHashes.filter((photoHash) => photoHash !== imageHash));
      }
    },
    [selectedHashes],
  );

  const handlePhotoSelection = (isSelected: boolean, photo: RoomPhotoFile) => {
    updateSelectedHashes(isSelected, photo.imageHash);
  };

  const validateFiles = (photos: FileList | File[]) => {
    const { validFiles, error } = PhotoLogic.validateImageFiles(photos);
    setError(error || "");
    return validFiles;
  };

  const updatePhotosWithPreparedFiles = (files: FileList | File[]) => {
    if (!files.length) return;

    setIsPhotoUploading(true);
    const preparedFiles: RoomPhotoFile[] = Array.from(files).map((file) => ({
      file,
      imageHash: URL.createObjectURL(file),
      contentType: file.type,
      loading: true,
    }));

    setPhotoFiles([...photoFiles, ...preparedFiles]);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && updatePhotosWithPreparedFiles(validateFiles(e.target.files));
  };

  const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files: File[] = [];
    if (e.dataTransfer.items) {
      Array.from(e.dataTransfer.items).forEach((item) => {
        if (item.kind === "file") {
          const file = item.getAsFile();
          file && files.push(file);
        }
      });
    } else if (e.dataTransfer.files) {
      Array.from(e.dataTransfer.files).forEach((file: File) => {
        files.push(file);
      });
    } else {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "PhotosContent.tsx",
        "handleDropError",
        [e],
        "The dataTransfer files neither items were found.",
      );
    }

    files.length && updatePhotosWithPreparedFiles(validateFiles(files));
  };

  const onSelectPhotosClick = () => {
    handleSelectRoomPhotos(selectedHashes);
  };

  useEffect(() => {
    const uploadPhotoLinks = async () => {
      if (!isPhotoUploading || !requestSearchId) return;

      const filteredPhotos = await PhotoLogic.uploadPhotoLinks(
        photoFiles,
        requestSearchId,
        setError,
        updateSelectedHashes,
      );
      setPhotoFiles(filteredPhotos as RoomPhotoFile[]);
      setIsPhotoUploading(false);
    };
    uploadPhotoLinks();
  }, [isPhotoUploading, photoFiles, requestSearchId, setPhotoFiles, updateSelectedHashes]);

  return (
    <Dialog
      keepMounted
      className={styles.baseModal}
      PaperComponent={CustomPaper}
      open={isOpen}
      onClose={handleClose}
    >
      <Draggable
        position={{ x: 0, y: 0 }}
        onStop={handleDragStop}
        handle={"#room-photo-drag-bar"}
        axis="y"
        bounds={{ top: 0, bottom: 500 }}
        onDrag={handleDrag}
      >
        <DialogContent className={styles.baseModalContent}>
          {isDesktop ? (
            <div className={styles.dialogWithExitBtnContainer}>
              <div className={styles.dialogWithExitExitBtnInner}>
                <IconButton color="inherit" onClick={handleClose} data-testid="close-button">
                  <CloseIcon color="inherit" />
                </IconButton>
              </div>
            </div>
          ) : (
            <DragBar dragId={"room-photo-drag-bar"} />
          )}
          <div className={styles.modalTitleWrapper}>
            <Typography className={styles.modalTitle}>
              Choose photos from the venue gallery or upload new
            </Typography>
            <Typography className={cn(styles.modalSubtitle, { [styles.error]: error })}>
              {error || (
                <span>
                  To reorder images, please visit the{" "}
                  <Link
                    className={styles.link}
                    to={`/listings/venue/${requestSearchId}?category=photos`}
                  >
                    Photos tab
                  </Link>
                </span>
              )}
            </Typography>
          </div>
          <div className={styles.photoList}>
            <UploadPlaceholder
              handleDrop={handleDrop}
              isDisabled={false}
              handleFileUpload={handleFileChange}
              ref={hiddenFileInputRef}
              dropZoneClassName={styles.dropZone}
            />
            {photoFiles.map((photo, index) => (
              <RoomPhoto
                key={photo.imageHash}
                photo={photo}
                isSelected={!!photo.imageHash && selectedHashes.includes(photo.imageHash)}
                index={index}
                handleDeleteIconClick={handleDeleteIconClick}
                handlePhotoSelection={handlePhotoSelection}
              />
            ))}
          </div>
          <BaseButton
            className={styles.confirmButton}
            onClick={onSelectPhotosClick}
            disabled={isPhotoUploading}
          >
            Select photos
          </BaseButton>
        </DialogContent>
      </Draggable>
    </Dialog>
  );
}

export default RoomPhotosPopup;
