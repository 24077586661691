import { useMemo } from "react";
import { EMAIL_VALIDATION_REGEXP, Log } from "blace-frontend-library";
import { FormikProps, useFormik } from "formik";
import * as Yup from "yup";
import { InputList } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/ContactContent/components/ContactForm/ContactForm";
import { LISTING_TEXT_REG_EXP } from "@/src/const";
import { FormLogic } from "@/src/model";
import { SearchContactV2 } from "@/src/type/blaceV2/search/SearchType";
import { uniqueId } from "@/src/util";

interface ContactSaveData {
  contacts: Partial<SearchContactV2>[];
}

interface UseContactFormProps {
  contactData?: Partial<SearchContactV2>;
  contactSaveHandler?: (data: ContactSaveData) => Promise<Record<string, string>>;
}

export interface ContactFormType {
  [InputList.FirstName]: string;
  [InputList.LastName]: string;
  [InputList.Email]: string;
}

export function useContactForm({ contactData, contactSaveHandler }: UseContactFormProps): {
  formik: FormikProps<ContactFormType>;
} {
  const defaultId = contactData?.id ?? uniqueId();
  const defaultFirstName = contactData?.firstName ?? "";
  const defaultLastName = contactData?.lastName ?? "";
  const defaultEmail = contactData?.email ?? "";

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        [InputList.FirstName]: Yup.string()
          .required("First name is required")
          .matches(
            LISTING_TEXT_REG_EXP,
            "Please enter a first name using letters, numbers or punctuation characters",
          )
          .min(1, "Please enter first name with a character count between 1 and 64")
          .max(64, "Please enter first name with a character count between 1 and 64"),
        [InputList.LastName]: Yup.string()
          .required("Last name is required")
          .matches(
            LISTING_TEXT_REG_EXP,
            "Please enter a last name using letters, numbers or punctuation characters",
          )
          .min(1, "Please enter first name with a character count between 1 and 64")
          .max(64, "Please enter first name with a character count between 1 and 64"),
        [InputList.Email]: Yup.string()
          .required("Email is required")
          .matches(EMAIL_VALIDATION_REGEXP, "Please enter a valid email")
          .max(255, "Please enter email with a character count up to 255"),
      }),
    [],
  );

  const formik = useFormik<ContactFormType>({
    enableReinitialize: true,
    validateOnMount: true,
    initialValues: {
      [InputList.FirstName]: defaultFirstName,
      [InputList.LastName]: defaultLastName,
      [InputList.Email]: defaultEmail,
    },
    validationSchema,
    onSubmit: async (values, { setFieldError, setFieldTouched }) => {
      const formValuesData = {
        contacts: [
          {
            firstName: Boolean(values[InputList.FirstName])
              ? values[InputList.FirstName]
              : undefined,
            lastName: Boolean(values[InputList.LastName]) ? values[InputList.LastName] : undefined,
            email: Boolean(values[InputList.Email]) ? values[InputList.Email] : undefined,
            isPrimary: true,
            isActive: true,
            id: defaultId,
          },
        ],
      };
      // handle backend validation
      const unknownFields: string[] = [];
      const errors = contactSaveHandler ? await contactSaveHandler(formValuesData) : {};

      FormLogic.handleServerErrors(errors, values, setFieldError, unknownFields);

      // handle a case when backend returned non known fields
      if (Boolean(unknownFields.length)) {
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "useContactForm.tsx",
          "onSubmitError",
          [unknownFields],
          "Unknown fields were returned from the server with errors.",
        );
        setFieldTouched(InputList.FirstName, true, false);
      }
    },
  });

  return {
    formik,
  };
}
